import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/outline'
import { Link } from 'react-router-dom'
// import { ExternalLinkIcon } from '@heroicons/react/solid'

export const ContactComponent = () => {

   const [open, setOpen] = useState(false)

    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [organisation, setOrganisation] = useState("")
    const [phone, setPhone] = useState("")
    const [message, setMessage] = useState("")
    const [aboutUs, setAboutUs] = useState("")

    const handleSubmit = (event) => {
      
      event.preventDefault();

      const formData = new URLSearchParams();

      formData.append("firstName", firstName)
      formData.append("lastName", lastName)
      formData.append("email", email)
      formData.append("organisation", organisation)
      formData.append("phone", phone)
      formData.append("message", message)
      formData.append("aboutUs", aboutUs)

      const url = "https://ukow5t5gx4.execute-api.us-east-1.amazonaws.com/contact/"
  
      const requestOptions = {
          method: 'POST',
          mode: "cors",
          cache: "no-cache",
          redirect: "follow",
          headers: {
            'Accept': "application/json",
            'Content-Type': "application/x-www-form-urlencoded",
          },
          body: formData,
      }

    
      fetch(url, requestOptions)
          .then((res) =>  {
            if(!res.ok) throw new Error(res.status);
            else setOpen(true)
            return res.json();
          })
          .then((data) => console.log("FROM Server : " + data))
          .catch(error => console.log(error))
      }
    
    const handleModalClose = () => {
      setOpen(false)
      setFirstName("")
      setLastName("")
      setEmail("")
      setOrganisation("")
      setPhone("")
      setMessage("")
      setAboutUs("")
    }

    return (
     <>
      <div className="relative bg-white border-b pt-16">
        <div className="lg:absolute lg:inset-0">
          <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            <img
              className="h-56 w-full object-cover lg:absolute lg:h-full"
              src="https://images.pexels.com/photos/1170412/pexels-photo-1170412.jpeg?auto=compress&cs=tinysrgb&w=1567"
              alt=""
            />
          </div>
        </div>
        <div className="relative py-16 px-4 sm:py-24 sm:px-6 lg:px-8 lg:container lg:mx-auto lg:py-32 lg:grid lg:grid-cols-2">
          <div className="lg:pr-8">
            <div className="max-w-md mx-auto sm:max-w-lg lg:mx-0">
              <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">Let's work together</h2>
              <p className="mt-4 text-lg text-gray-500 sm:mt-3">
                We’d love to hear from you! Send us a message using the form opposite, or email us. We’d love to hear from
                you! Send us a message using the form opposite, or email us.
              </p>
              <form 
                    // method="POST" 
                    // action="https://q4d9c33b4d.execute-api.us-east-1.amazonaws.com/contact/" 
                    onSubmit={handleSubmit}
                    className="mt-9 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                <div>
                  <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
                    First name
                  </label>
                  <div className="mt-1">
                    <input
                      required
                      type="text"
                      name="firstName"
                      id="firstName"
                      autoComplete="given-name"
                      className="block w-full shadow-sm sm:text-sm focus:ring-orange-800 focus:border-orange-800 border-gray-300 rounded-md"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
                    Last name
                  </label>
                  <div className="mt-1">
                    <input
                      required
                      type="text"
                      name="lastName"
                      id="lastName"
                      autoComplete="family-name"
                      className="block w-full shadow-sm sm:text-sm focus:ring-orange-800 focus:border-orange-800 border-gray-300 rounded-md"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    Email
                  </label>
                  <div className="mt-1">
                    <input
                      required
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      className="block w-full shadow-sm sm:text-sm focus:ring-orange-800 focus:border-orange-800 border-gray-300 rounded-md"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label htmlFor="organisation" className="block text-sm font-medium text-gray-700">
                    Organisation
                  </label>
                  <div className="mt-1">
                    <input
                      required
                      type="text"
                      name="organisation"
                      id="organisation"
                      autoComplete="organization"
                      className="block w-full shadow-sm sm:text-sm focus:ring-orange-800 focus:border-orange-800 border-gray-300 rounded-md"
                      value={organisation}
                      onChange={(e) => setOrganisation(e.target.value)}
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <div className="flex justify-between">
                    <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                      Phone
                    </label>
                    <span id="phone-description" className="text-sm text-gray-500">
                      Optional
                    </span>
                  </div>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="phone"
                      id="phone"
                      autoComplete="tel"
                      aria-describedby="phone-description"
                      className="block w-full shadow-sm sm:text-sm focus:ring-orange-800 focus:border-orange-800 border-gray-300 rounded-md"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <div className="flex justify-between">
                    <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                      How can we help you?
                    </label>
                    <span id="message-description" className="text-sm text-gray-500">
                      Max. 500 characters
                    </span>
                  </div>
                  <div className="mt-1">
                    <textarea
                      required
                      id="message"
                      name="message"
                      aria-describedby="message-description"
                      rows={4}
                      className="block w-full shadow-sm sm:text-sm focus:ring-orange-800 focus:border-orange-800 border border-gray-300 rounded-md"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label htmlFor="aboutUs" className="block text-sm font-medium text-gray-700">
                    How did you hear about us?
                  </label>
                  <div className="mt-1">
                    <input
                      required
                      type="text"
                      name="aboutUs"
                      id="aboutUs"
                      className="shadow-sm focus:ring-orange-800 focus:border-orange-800 block w-full sm:text-sm border-gray-300 rounded-md"
                      value={aboutUs}
                      onChange={(e) => setAboutUs(e.target.value)}
                    />
                  </div>
                </div>
                <div className="text-right sm:col-span-2">
                  <button
                    type="submit"
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange-900 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-800"
                    
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        
      </div>
      <div className="bg-gradient-to-r from-orange-400 to-red-600">
          <div className="max-w-md mx-auto text-center py-16 px-4 sm:max-w-7xl sm:py-24 sm:px-6 lg:px-8 lg:py-32">
            <h2 className="text-3xl font-extrabold sm:text-4xl">
              {/* <span className="block text-white">Looking for a new career?</span> */}
              <span className="block text-2xl text-white">
                There are multiple graduate assistantships positions available.
              </span>
              <span className="block text-4xl text-orange-900">Contact us to know more.</span>
            </h2>
            {/* <a
              href="/"
              className="mt-8 w-full inline-flex items-center justify-center py-3 px-5 bg-white border border-transparent rounded-md shadow-md text-base font-medium text-grape-600 hover:bg-grape-50 sm:w-auto"
            >
              <span>See open positions</span>
              <ExternalLinkIcon className="ml-3 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            </a> */}
          </div>
        </div>
        <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={handleModalClose}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    Thank You
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Your message has been received. <br /> We will update you soon.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <Link
                  to="/"
                  type="button"
                  className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-orange-600 text-base font-medium text-white hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-800 sm:text-sm"
                >
                  Go back
                </Link>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
      </> 
    )
  }
  