import { FullFooter } from "../components/fullFooter"
import { Navbar } from "../components/navbar"

export const Policy = () => {
    return (
        <>
        <Navbar />
        <div className="bg-gray-100 pt-16">   
            <div className="relative py-16 border-b bg-white overflow-hidden">
                <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
                <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
                    <svg
                    className="absolute top-12 left-full transform translate-x-32"
                    width={404}
                    height={384}
                    fill="none"
                    viewBox="0 0 404 384"
                    >
                    <defs>
                        <pattern
                        id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                        x={0}
                        y={0}
                        width={20}
                        height={20}
                        patternUnits="userSpaceOnUse"
                        >
                        <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                        </pattern>
                    </defs>
                    <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
                    </svg>
                    <svg
                    className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
                    width={404}
                    height={384}
                    fill="none"
                    viewBox="0 0 404 384"
                    >
                    <defs>
                        <pattern
                        id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                        x={0}
                        y={0}
                        width={20}
                        height={20}
                        patternUnits="userSpaceOnUse"
                        >
                        <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                        </pattern>
                    </defs>
                    <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
                    </svg>
                    <svg
                    className="absolute bottom-12 left-full transform translate-x-32"
                    width={404}
                    height={384}
                    fill="none"
                    viewBox="0 0 404 384"
                    >
                    <defs>
                        <pattern
                        id="d3eb07ae-5182-43e6-857d-35c643af9034"
                        x={0}
                        y={0}
                        width={20}
                        height={20}
                        patternUnits="userSpaceOnUse"
                        >
                        <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                        </pattern>
                    </defs>
                    <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
                    </svg>
                </div>
                </div>
                <div className="relative px-4 sm:px-6 lg:px-8">
                <div className="text-lg max-w-prose mx-auto">
                    <h1>
                    <span className="mt-10 block text-4xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-6xl">
                        Privacy policy
                    </span>
                    <span className="block mt-4 text-sm text-center text-stone-600 font-semibold tracking-wide">
                    Last updated on December 23, 2022
                    </span>
                    </h1>
                    <p className="mt-8 text-lg text-gray-500 leading-8">
                    Intelligence Transportation Systems Lab provides the following information relating to your privacy when using websites in the itranss.com domain. This privacy policy covers all itranss.com websites unless otherwise indicated on a specific website.
                    </p>
                </div>
                <div className="mt-6 text-lg prose prose-indigo prose-lg text-gray-500 mx-auto">

                    <p className="leading-8">
                    Protecting your privacy is very important to us. Our Web site links to other Intelligence Transportation Systems & Services (ITranSS) sites, federal agency sites and occasionally, to private organizations. Once you leave the primary itranss.com site, you are subject to the privacy policy for the site(s) you are visiting. We do not collect any personally identifiable information (PII) about you during your visit to ITRANSS Web sites unless you choose to provide it to us. We do, however, collect some data about your visit to our Web site to help us better understand how the public uses the site and how to make it more helpful. We collect information from visitors who read, browse, and/or download information from our Web site. ITRANSS never collects information for commercial marketing or any purpose unrelated to the ITRANSS mission and goals.
                    <br />
                    When visitors submit questions or comments via the Ask ITRANSS online form, ITRANSS staff responds to the inquiries and files them. Only designated staff members requiring access to the emails to respond, may view, or answer them.
                    </p>

                    <h2>Types of Information Collected</h2>
                    <p>
                    When you browse through any Web site, certain information about your visit can be collected. We automatically collect and temporarily store the following type of information about your visit:
                    </p>
                    <ul>
                    <li>Domain from which you access the Internet;</li>
                    <li>IP address (an IP address is a number that is automatically assigned to a computer when surfing the Web);</li>
                    <li>Operating system and information about the browser used when visiting the site;</li>
                    <li>Date and time of your visit;</li>
                    <li>Pages you visited;</li>
                    <li>Address of the Web site that connected you to an ITranSS Web site (such as google.com or bing.com); and,</li>
                    <li>Demographic and interest data.</li>
                    </ul>
                    <p>
                    We use this information to measure the number of visitors to our site and its various sections and to help make our site more useful to visitors. This information cannot be used to identify you as an individual.
                    </p>
                    <h2>How we Collects Information</h2>
                    <p>
                    Itranss.com uses Google Analytics to collect the information in the bulleted list in the Types of Information Collected section above. Google Analytics gathers information automatically and continuously. No Personally Identifiable Information (PII) is collected. ITRANSS staff conducts analyses and reports on the aggregated data from Google Analytics and those reports are only available to Itranss.com managers, members of the Itranss.com communications and web teams, and other designated staff who require this information to perform their duties.
                    </p>
                    <h2>How Personal Information Is Protected</h2>
                    <p>
                    You do not have to give us personal information to visit the ITRANSS Web sites. However, if you choose to receive alerts or e-newsletters, we collect your email address to complete the subscription process.
                    </p>
                    <p>
                    If you choose to provide us with personally identifiable information, that is, information that is personal in nature and which may be used to identify you, through an e-mail message, request for information, paper or electronic form, questionnaire, customer satisfaction survey, epidemiology research study, etc., we will maintain the information you provide only as long as needed to respond to your question or to fulfill the stated purpose of the communication. If we store your personal information in a record system designed to retrieve information about you by personal identifier (name, personal email address, home mailing address, personal or mobile phone number, etc.), so that we may contact you, we will safeguard the information you provide to us in accordance with the Privacy Act of 1974, as amended (5 U.S.C. Section 552a).
                    </p>
                    <p>
                    If ITRANSS operates a record system designed to retrieve information about you in order to accomplish its mission, a Privacy Act Notification Statement should be prominently and conspicuously displayed on the public-facing website or form which asks you to provide personally identifiable information. The notice must address the following five criteria:
                    </p>
                    <ol>
                    <li>ITRANSS legal authorization to collect information about you</li>
                    <li>Purpose of the information collection</li>
                    <li>Routine uses for disclosure of information outside of ITRANSS</li>
                    <li>Whether the request made of you is voluntary or mandatory under law</li>
                    <li>Effects of non-disclosure if you choose to not provide the requested information</li>
                    </ol>
                    <p>
                    For further information about ITRANSS privacy policy, please contact the ITRANSS Senior Official for Privacy at <a href="mailto:contact@itranss.com">contact@itranss.com</a>.
                    </p>
                </div>
                </div>
            </div>
        </div>
        <FullFooter />
      </>
    )
  }
  