
export const NewsComponent = ({news}) => {
return (
    <section className="text-gray-600 pt-64 body-font">
        <div aria-hidden="true" className="absolute inset-0">
            <div className="absolute inset-0 mx-auto overflow-hidden">
            <img
                src="https://images.pexels.com/photos/3206120/pexels-photo-3206120.jpeg?auto=compress"
                alt=""
                className="w-full h-full object-center object-cover"
            />
            </div>
            <div className="absolute inset-0 bg-white bg-opacity-25" />
            <div className="absolute inset-0 bg-gradient-to-t from-white via-white" />
        </div>

        {/* Callout */}
        <section
            aria-labelledby="sale-heading"
            className="relative container mx-auto pt-32 px-4 flex flex-col items-center text-center sm:px-6 lg:px-8"
        >
            <div className="max-w-2xl mx-auto lg:max-w-none">
            <h2
                id="sale-heading"
                className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl lg:text-6xl"
            >
                Latest News and Articles
            </h2>
            <p className="mt-4 max-w-xl mx-auto text-xl text-gray-600">
                Most of our products are limited releases that won't come back. Get your favorite items while they're in
                stock.
            </p>
            </div>
        </section>

        <div className="relative border-b px-5 py-24">
            <div className="container mx-auto">
                <div className="flex flex-wrap -m-4">
                    { news.map( value => (
                        <div key = {value.id} className="md:w-1/3 p-4">
                        <div className="border border-gray-200 rounded-lg">
                        <img className="lg:h-48 md:h-36 w-full border-b rounded-t-lg object-cover object-center" src={value.image} alt={value.title} />
                        <div className="p-6">
                            {value.title.length < 76
                                ?  <h1 className="title-font text-lg font-medium text-gray-900 mb-3">{value.title}</h1>
                                :  <h1 className="title-font text-lg font-medium text-gray-900 mb-3">{value.title.substring(0, 76)}...</h1>
                            }
                            <div className="flex items-center flex-wrap mt-auto w-full">
                                <span className="inline-flex items-center mr-auto text-sm text-gray-500 mb-2">
                                {value.date}
                                </span>
                                { value.link 
                                ? <a target="_blank" rel="noreferrer" href={value.link} className="text-orange-800 inline-flex items-center">Read more
                                    <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path d="M5 12h14"></path>
                                    <path d="M12 5l7 7-7 7"></path>
                                    </svg>
                                    </a> 
                                : null
                                }
                            </div>
                        </div>
                        </div>
                        </div>
                    ))}
                </div>
            </div>
        
        </div>
    </section>
)
}