export const AboutComponent = ({collaborators}) => {

  const stats = [
    { id: '1',label: 'Founded', value: '2018' },
    { id: '2',label: 'Projects', value: '15+' },
    { id: '3',label: 'Publications', value: '40+' },
    { id: '4',label: 'Awarded', value: '$3M+' },
  ]

  return (
    <div className="border-b">
      {/* Header */}
      <div className="relative pt-20 pb-56 2xl:pb-[30rem] bg-gray-800">
        <div className="absolute inset-0">
          <img
            className="w-full h-full object-cover"
            src="https://img.freepik.com/free-photo/diverse-people-working-office_53876-104681.jpg?w=1380"
            alt=""
          />
          <div className="absolute inset-0 bg-gradient-to-t from-gray-900 to-gray-400 mix-blend-multiply" aria-hidden="true" />
        </div>
        <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-48 sm:px-6 lg:px-8">
          {/* Background Image heigth */}
        </div>
      </div>

      {/* Overlapping cards */}
      <section
        className="-mt-80 container mx-auto relative z-10 px-4 sm:px-6 lg:px-8"
        aria-labelledby="project-heading">
        <div>
            <div className="rounded-lg">
            <div className="relative py-16 md:px-10 pb-14 mb-14">
              <h1 className="text-4xl text-white font-semibold tracking-tight md:text-5xl lg:text-6xl ">About Us</h1>
              {/* <p className="mt-6 text-lg md:text-2xl text-gray-100">
              We're advancing the state-of-the-art in artificial intelligence through fundamental and applied research in open collaboration with the community.
              </p> */}
              <div className="h-1 mt-4 w-full bg-white rounded"></div>
            </div>
            </div>
        </div>
      </section>

      <div className="relative bg-white py-16 sm:py-24">
      <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
        <div className="relative sm:py-16 lg:py-0">
          <div aria-hidden="true" className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen">
            <div className="absolute inset-y-0 right-1/2 w-full bg-gray-50 rounded-r-3xl lg:right-72" />
            <svg
              className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12"
              width={404}
              height={392}
              fill="none"
              viewBox="0 0 404 392"
            >
              <defs>
                <pattern
                  id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={392} fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)" />
            </svg>
          </div>
          <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
            {/* Testimonial card*/}
            <div className="relative pt-64 pb-10 rounded-2xl shadow-xl overflow-hidden">
              <img
                className="absolute inset-0 h-full w-full object-cover"
                src="https://rowan-intelligence-transportation-system.s3.amazonaws.com/media/images/default.jpeg"
                alt=""
              />
            <div className="h-28"></div>
            </div>
          </div>
        </div>

        <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
          {/* Content area */}
          <div className="pt-12 sm:pt-16 lg:pt-20">
            <h2 className="text-3xl text-gray-900 font-extrabold tracking-tight sm:text-4xl">
            Innovative solutions for modern mobility
            </h2>
            <div className="mt-6 text-gray-500 space-y-6">
              <p className="text-base leading-7">
              At ITranSS, we are passionate about using innovative technologies to solve the complex challenges facing modern transportation systems. Our team of experts works with clients across a wide range of industries and academia to design and implement customized solutions that increase efficiency, reduce costs, and improve safety of transportation systems.
              </p>
            </div>
          </div>

          {/* Stats section */}
          <div className="mt-10">
            <div className="grid grid-cols-2 gap-x-4 gap-y-8">
              {stats.map((stat) => (
                <div key={stat.id} className="border-t-2 border-gray-100 pt-6">
                  <dt className="text-base font-medium text-gray-500">{stat.label}</dt>
                  <dd className="text-3xl font-extrabold tracking-tight text-gray-900">{stat.value}</dd>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>

        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <p className="text-center text-base font-semibold uppercase text-gray-600 tracking-wider">
          Collaborated with over 14 organisations
        </p>
        <div className="mt-6 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-8">
          { collaborators.map( item => (
              <div key={item.id} className="col-span-1 flex justify-center py-8 px-8 bg-gray-100">
              <img
                className="max-h-12"
                src={item.link}
                alt={item.id}
              />
            </div>
          ))}
        </div>
      </div>

      <div className="bg-gradient-to-r from-sky-400 to-indigo-600">
          <div className="max-w-md mx-auto text-center py-16 px-4 sm:max-w-7xl sm:py-24 sm:px-6 lg:px-8 lg:py-32">
            <h2 className="text-3xl font-extrabold sm:text-4xl">
              {/* <span className="block text-white">Looking for a new career?</span> */}
              <span className="block text-2xl md:text-2xl text-white">
                There are multiple graduate assistantships positions available.
              </span>
              <span className="block text-2xl md:text-4xl text-gray-700">Contact us to know more.</span>
            </h2>
            {/* <a
              href="/"
              className="mt-8 w-full inline-flex items-center justify-center py-3 px-5 bg-white border border-transparent rounded-md shadow-md text-base font-medium text-grape-600 hover:bg-grape-50 sm:w-auto"
            >
              <span>See open positions</span>
              <ExternalLinkIcon className="ml-3 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            </a> */}
          </div>
        </div>

    </div>
  )
}
