import React from 'react'
import axios from 'axios';

import { AwardHero } from "../components/awardHero.component"
import { FullFooter } from "../components/fullFooter"
import { Navbar } from "../components/navbar"

import xyz from "../assets/json/awards.json"

class Awards extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            awards: xyz
        };
    }

    componentDidMount() { 
        this.getDatas();
    }

    async getDatas() {
        let data = await axios
          .get("https://admin.itranss.com/api/awards/")
          .then(function(response) {
            return response;
          })
          .catch(function(error) {
            console.log(error);
          });
          if (data > 0) {
            this.setState(null);
            this.setState({ awards: data });
          }
        
      }


    render() {

        const {awards} = this.state;

        return (
            <div>
                <Navbar />
                <AwardHero data={awards.data}/>
                <FullFooter />
            </div>
        )
    }
}

export default Awards;