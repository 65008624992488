export const PeopleComponent = ({team, faculty, student, alumni}) => {
    return (
        <div className="bg-white border-b pt-16 ">
                <div className="container mx-auto py-12 px-4 text-center sm:px-6 lg:px-8">
                    <div className="space-y-8 sm:space-y-12">
                    {/* <div className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl">
                        <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">The People</h2>
                    </div> */}
                    </div>
                        <div className="mx-auto text-left py-12 px-4 container sm:px-6 lg:px-8">
                        <div className="w-full mb-8 lg:mb-16">
                            <h1 className="sm:text-3xl text-2xl font-bold title-font mb-2 text-gray-900">Faculty</h1>
                            <div className="h-1 w-full bg-orange-800 rounded"></div>
                        </div>
                            <div className="space-y-12 lg:grid lg:grid-cols-2 lg:gap-8 lg:space-y-0">
                            <div className="lg:col-span-2">
                                <ul
                                className="space-y-12 sm:divide-y sm:divide-gray-200 sm:space-y-0 sm:-mt-8 lg:gap-x-8 lg:space-y-0 text-left"
                                >
                                {faculty.map((person) => (
                                    <div key={person.id} className="sm:py-8 lg:pt-8 lg:pb-0">
                                    <div className="space-y-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-6 sm:space-y-0">
                                        <div className="aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-4">
                                            <img className="object-cover mx-auto lg:h-3/4 lg:w-3/4  2xl:w-2/3 2xl:h-3/5 shadow-lg rounded-lg" src={person.image} alt={person.name} />
                                        </div>
                                        <div className="sm:col-span-2">
                                        <div className="space-y-2 2xl:space-y-4">
                                            <div className="text-lg leading-6 font-medium space-y-1">
                                            <h3>{person.name}</h3>
                                            <p className="text-orange-900">{person.position}</p>
                                            </div>
                                            <div className="text-lg">
                                                <p className="text-gray-500 whitespace-pre-line">{person.bio}</p>
                                            </div>
                                            <div className="text-lg">
                                             <p className="text-gray-500"><span className="font-semibold">Office:</span> {person.office_address}</p>
                                            </div>
                                            <div className="text-lg">
                                             <p className="text-gray-500"><span className="font-semibold">Work:</span> {person.work_contact}</p>
                                            </div>
                                            <div className="text-lg">
                                             <p className="text-gray-500"><span className="font-semibold">Fax:</span> {person.fax}</p>
                                            </div>
                                            <div className="text-lg text-gray-500">
                                            <span className="font-semibold">Email: </span>
                                             <a target="_blank" rel="noreferrer" href={"mailto:" + person.email} >{person.email}</a>
                                            </div>
                                            <div className="text-lg text-gray-500">
                                            <span className="font-semibold">Website: </span> 
                                             <a target="_blank" rel="noreferrer" href={person.website}>
                                             {person.website}
                                             </a>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                ))}
                                </ul>
                            </div>
                            </div>

                        
                            
                            <div className="mx-auto py-10 md:py-4">
                                <div className="w-full mb-8 lg:mb-16">
                                    <h1 className="sm:text-3xl text-2xl font-bold title-font text-left mb-2 text-gray-900">Team</h1>
                                <div className="h-1 w-full bg-orange-800 rounded"></div>
                            </div>
                                <div className="flex flex-wrap -m-4">
                                {team.map((person) => (
                                <div key={person.id} className="p-4 lg:w-1/4 md:w-1/2">
                                    <div className="h-full flex flex-col items-center text-center">
                                    <img 
                                        alt="team" 
                                        className="flex-shrink-0 h-40 w-40 rounded-full xl:w-56 xl:h-56 object-cover object-center mb-4" 
                                        src={person.image} />
                                    <div className="w-full">
                                        <h2 className="title-font font-medium text-lg text-gray-900">{person.name}</h2>
                                        <h3 className="text-gray-500 mb-3">{person.position}</h3>
                                        <span className="inline-flex">
                                        <a target="_blank" rel="noreferrer" href={person.linkedin} className="text-gray-500">
                                          <img  className="w-5 h-5" src="https://iconmonstr.com/wp-content/g/gd/makefg.php?i=../releases/preview/2012/png/iconmonstr-linkedin-1.png&r=106&g=114&b=128" alt="linkedIn" />
                                        </a>
                                        {/* <a target="_blank" rel="noreferrer" href={"mailto:" + person.email} className="ml-2 text-gray-500">
                                            <img className="p-0.5 w-6 h-6" src="https://iconmonstr.com/wp-content/g/gd/makefg.php?i=../releases/preview/2018/png/iconmonstr-gmail-1.png&r=106&g=114&b=128" alt="email"/>
                                        </a> */}
                                        </span>
                                    </div>
                                    </div>
                                </div>
                                ))}
                                </div>
                            </div>


                            <div className="mx-auto py-10 md:py-4">
                                <div className="w-full mb-8 lg:mb-16">
                                    <h1 className="sm:text-3xl text-2xl font-bold title-font text-left mb-2 text-gray-900">Developers </h1>
                                <div className="h-1 w-full bg-orange-800 rounded"></div>
                            </div>
                                <div className="flex flex-wrap -m-4">
                                {student.map((person) => (
                                <div key={person.id} className="p-4 lg:w-1/4 md:w-1/2">
                                    <div className="h-full flex flex-col items-center text-center">
                                    <img 
                                        alt="team" 
                                        className="flex-shrink-0 h-40 w-40 rounded-full xl:w-56 xl:h-56 object-cover object-center mb-4" 
                                        src={person.image} />
                                    <div className="w-full">
                                        <h2 className="title-font font-medium text-lg text-gray-900">{person.name}</h2>
                                        <h3 className="text-gray-500 mb-3">{person.position}</h3>
                                        {/* <p className="mb-4">DIY tote bag drinking vinegar cronut adaptogen squid fanny pack vaporware.</p> */}
                                        <span className="inline-flex">
                                        <a target="_blank" rel="noreferrer" href={person.linkedin} className="text-gray-500">
                                          <img  className="w-5 h-5" src="https://iconmonstr.com/wp-content/g/gd/makefg.php?i=../releases/preview/2012/png/iconmonstr-linkedin-1.png&r=106&g=114&b=128" alt="linkedIn" />
                                        </a>
                                        {/* <a target="_blank" rel="noreferrer" href={"mailto:" + person.email} className="ml-2 text-gray-500">
                                            <img className="p-0.5 w-6 h-6" src="https://iconmonstr.com/wp-content/g/gd/makefg.php?i=../releases/preview/2018/png/iconmonstr-gmail-1.png&r=106&g=114&b=128" alt="email"/>
                                        </a> */}
                                        </span>
                                    </div>
                                    </div>
                                </div>
                                ))}
                                </div>
                            </div>

                            <div className="mx-auto py-10 md:py-4">
                                <div className="w-full mb-8 lg:mb-16">
                                    <h1 className="sm:text-3xl text-2xl font-bold title-font text-left mb-2 text-gray-900">Alumni</h1>
                                <div className="h-1 w-full bg-orange-800 rounded"></div>
                            </div>
                                <div className="flex flex-wrap -m-4">
                                {alumni.map((person) => (
                                <div key={person.id} className="p-4 lg:w-1/4 md:w-1/2">
                                    <div className="h-full flex flex-col items-center text-center">
                                    <img 
                                        alt="team" 
                                        className="flex-shrink-0 h-40 w-40 rounded-full xl:w-56 xl:h-56 object-cover object-center mb-4" 
                                        src={person.image} />
                                    <div className="w-full">
                                        <h2 className="title-font font-medium text-lg text-gray-900">{person.name}</h2>
                                        <h3 className="text-gray-500 mb-3">{person.position}</h3>
                                        {/* <p className="mb-4">DIY tote bag drinking vinegar cronut adaptogen squid fanny pack vaporware.</p> */}
                                        <span className="inline-flex">
                                        <a target="_blank" rel="noreferrer" href={person.linkedin} className="text-gray-500">
                                          <img  className="w-5 h-5" src="https://iconmonstr.com/wp-content/g/gd/makefg.php?i=../releases/preview/2012/png/iconmonstr-linkedin-1.png&r=106&g=114&b=128" alt="linkedIn" />
                                        </a>
                                        {/* <a target="_blank" rel="noreferrer" href={"mailto:" + person.email} className="ml-2 text-gray-500">
                                            <img className="p-0.5 w-6 h-6" src="https://iconmonstr.com/wp-content/g/gd/makefg.php?i=../releases/preview/2018/png/iconmonstr-gmail-1.png&r=106&g=114&b=128" alt="email"/>
                                        </a> */}
                                        </span>
                                    </div>
                                    </div>
                                </div>
                                ))}
                                </div>
                            </div>
                        </div>
                </div>
                </div>
    )
}