import { FullFooter } from "../components/fullFooter"
import { Navbar } from "../components/navbar"

export const Terms = () => {
    return (
        <>
        <Navbar />
        <div className="pt-16 border-b">
        <div className="relative py-16 bg-white overflow-hidden">
            <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
            <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
                <svg
                className="absolute top-12 left-full transform translate-x-32"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
                >
                <defs>
                    <pattern
                    id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                    >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                    </pattern>
                </defs>
                <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
                </svg>
                <svg
                className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
                >
                <defs>
                    <pattern
                    id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                    >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                    </pattern>
                </defs>
                <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
                </svg>
                <svg
                className="absolute bottom-12 left-full transform translate-x-32"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
                >
                <defs>
                    <pattern
                    id="d3eb07ae-5182-43e6-857d-35c643af9034"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                    >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                    </pattern>
                </defs>
                <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
                </svg>
            </div>
            </div>
            <div className="relative px-4 sm:px-6 lg:px-8">
            <div className="text-lg max-w-prose mx-auto">
                <h1>
                <span className="mt-10 block text-4xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-6xl">
                            Terms & Condition
                        </span>
                        <span className="block mt-4 text-sm text-center text-stone-600 font-semibold tracking-wide">
                        Last updated on December 23, 2022
                        </span>
                </h1>
            </div>
            <div className="mt-16 text-lg prose prose-indigo prose-lg text-gray-500 mx-auto leading-8">
                <h3>Endorsement Disclaimer — Links to Other Sites</h3>
                <p>
                Our Web site has links to many other federal agencies, and in a few cases we link to private organizations. You are subject to that site's privacy policy when you leave our site. We proudly comply with 508 accessibility regulations, but we cannot be responsible for Section 508 compliance (accessibility) on other federal or private Web sites.
                <br />
                Reference in this Web site to any specific commercial products, process, service, manufacturer, or company does not constitute its endorsement or recommendation by the U.S. Government or Intelligent Transportation Systems & Services (ITranSS). ITranSS is not responsible for the contents of any "off-site" Web page referenced from this server.
                </p>

                <h3>Endorsement Disclaimer — Pop-Up Advertisements</h3>
                <p>
                When visiting our Web site, your Web browser may produce pop-up advertisements. These advertisements were most likely produced by other Web sites you visited or by third party software installed on your computer. The Intelligent Transportation Systems & Services (ITranSS) does not endorse or recommend products or services for which you may view a pop-up advertisement on your computer screen while visiting our site.
                </p>
                <h3>Information Disclaimer</h3>
                <p>
                The information provided using this Web site is only intended to be general summary information to the public. It is not intended to take the place of either the written law or regulations.
                </p>
                <p>It is not ITranSS’s intention to provide specific medical advice to users of the ITranSS Web site, instead we provide users with information to help them better understand their health, diagnosed conditions, and the current approaches related to treatment, prevention, screening, and supportive care. ITRANSS urges users to consult with a qualified health care professional for diagnosis and answers to their personal medical questions.</p>
                <p>
                Press releases and other materials meant for public use will not be posted to the ITranSS Web site unless they were developed by or for ITranSS.
                </p>
                <h3>Intrusion Detection</h3>
                <p>
                This site is maintained by the U.S. Government. It is protected by various provisions of Title 18, U.S. Code. Violations of Title 18 are subject to criminal prosecution in federal court.
                </p>
                <p>
                For site security purposes and to ensure that this service remains avaitisle to all users, we employ software programs to monitor traffic to identify unauthorized attempts to upload or change information, or otherwise cause damage. In the event of authorized law enforcement investigations, and pursuant to any required legal process, information from these sources may be used to help identify an individual.
                </p>
                </div>
            </div>
        </div>
        </div>
        <FullFooter />
      </>
    )
  }
  