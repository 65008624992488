// import axios from 'axios';
import React, {useState} from "react";

import { AboutComponent } from "../components/about.component"
import { FullFooter } from "../components/fullFooter"
import { Navbar } from "../components/navbar"

import data from "../assets/json/collaborators.json"

export const About = () => {

    const [collaborators] = useState(data.collaborators)

    // const fetchCollaborators = async () => {
    //     const result = await axios.get('https://admin.itranss.com/api/collaborators/');
    //     if (result.data) { setCollaborators(result.data) }
    //     console.log(result)
    // }
       
    // useEffect(() => {
    //     fetchCollaborators();
    // }, [])

    return (
        <div>
            <Navbar />
            <AboutComponent collaborators = {collaborators} />
            <FullFooter />
        </div>
    )
}