import { Link } from 'react-router-dom'

export const NotFound = () => {
    return (
        <main
        className="min-h-full h-screen bg-cover bg-bottom"
        style={{
          backgroundImage:
            'url("https://images.pexels.com/photos/3064258/pexels-photo-3064258.jpeg?auto=compress&cs=tinysrgb")',
        }}
      >
        <div className="max-w-7xl mx-auto px-4 py-16 text-center sm:px-6 sm:py-24 lg:px-8 lg:py-48">
          <p className="text-sm font-semibold text-black uppercase tracking-wide">404 error</p>
          <h1 className="mt-2 text-4xl font-extrabold text-white tracking-tight sm:text-5xl">
          This page does not exist.
          </h1>
          <p className="mt-2 text-lg font-medium text-black ">
            It looks like the page you’re looking for doesn't exist.
          </p>
          <div className="mt-6">
            <Link
              to='/'
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-black bg-white bg-opacity-70 sm:hover:bg-opacity-50"
            >
              Go back home
            </Link>
          </div>
        </div>
      </main>
    )
}