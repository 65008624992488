import axios from 'axios';
import React, {useState, useEffect} from "react";

import { Navbar } from "../components/navbar"
import { FullFooter } from "../components/fullFooter"
import { PublicationComponent } from "../components/publication.component"
import { HeroComponent } from "../components/hero.component"

import data from "../assets/json/publications.json"

export const Publication = () => {

    const [publication, setPublication] = useState(data.techinalReport)

    const fetchPublication = async () => {
        const result = await axios.get('https://admin.itranss.com/api/publication/');
        if (result.data > 0) { setPublication(result.data) }
    }

    useEffect(() => {
        fetchPublication();
    }, [])

    return (
        <div>
            <Navbar />
            <div className="bg-gray-100 pb-20"> 
            <HeroComponent
                title={"Publications"} 
                // desc={""} 
                img={"https://images.pexels.com/photos/256559/pexels-photo-256559.jpeg?auto=compress"} 
            />
            <PublicationComponent publication={publication} />
            </div>
            <FullFooter />
        </div>
    )
}