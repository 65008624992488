import { Route, Routes } from 'react-router-dom'
import { useState, useEffect } from 'react';

import Awards from './pages/awards';
import { Contact } from './pages/contact';
import { Home } from './pages/home'
import { News } from './pages/news';
import { NotFound } from './pages/notFound';
import { People } from './pages/people';
import { Projects } from './pages/projects';
import { Publication } from './pages/publication';
import { Presentation } from './pages/presentation';
import { Journal } from './pages/journal';
import { Book } from './pages/book';
// import { Conference } from './pages/conference';
import { Services } from './pages/services';
import { About } from './pages/about';
import { Terms } from './pages/terms';
import { Policy } from './pages/policy';

import fullLogo from './assets/media/fullLogo.png'
import { BarLoader } from 'react-spinners';
import ScrollToTop from './components/scrollToTop';



function App() {

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    
    setTimeout(() => {
      setLoading(false)
    }, 3000)

  }, [])

  return (
    <div>
      <ScrollToTop />
      {
        loading ? (
          <>
          <div className='flex flex-col my-auto justify-center items-center w-full h-screen'>
            
          <BarLoader
              color="#FFC503"
              width={300}
            />

            <img
                className="h-14 md:h-16 w-auto mb-1"
                src={fullLogo}
                alt="Intelligent Transportation Systems & Services (ITranSS)"
            />

          <BarLoader
              color="#FFC503"
              width={300}
            />
          </div>


          </>
        ) : 
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path='/publications' element={<Publication />} />
          <Route path='/about' element={<About />} />
          <Route path='/presentations' element={<Presentation />} />
          <Route path='/journals' element={<Journal />} />
          <Route path='/books' element={<Book />} />
          {/* <Route path='/conference' element={<Conference />} /> */}
          <Route path='/projects' element={<Projects />} />
          <Route path='/services' element={<Services />} />
          <Route path='/awards' element={<Awards/>} />
          <Route path='/people' element={<People />} />
          <Route path='/news' element={<News />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/terms' element={<Terms />} />
          <Route path='/policy' element={<Policy />} />
          <Route path='*' element={<NotFound />} />
         </Routes>
      }

    </div>
  );

}

export default App;

