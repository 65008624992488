export const AwardHero = (data) => {

    return (
      <div className="bg-white pt-16 border-b">
        <div aria-hidden="true" className="relative">
          <img
            src="https://images.pexels.com/photos/8177922/pexels-photo-8177922.jpeg?auto=compress&cs=tinysrgb"
            alt=""
            className="w-full h-96 object-center object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-white" />
        </div>
  
        <div className="relative -mt-12 container mx-auto pb-16 px-4 sm:pb-24 sm:px-6 lg:px-8">
          <div className="max-w-2xl mx-auto text-center lg:max-w-4xl">
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-5xl lg:text-6xl">Awards & Recognition</h2>
            <p className="mt-4 text-gray-500 text-xl">
              We are proud of our achievers to have proved their success through hard-work and dedication which has enabled them to make their dreams come true. 
            </p>
          </div>

          <section className="text-gray-600 body-font">
            <div className="container px-5 py-24 mx-auto">
              <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4">
                {data.data.map((award) => (
                    <div key={award.id} className="p-4 md:w-1/3 sm:mb-0 mb-6">
                    <div className="rounded-lg h-64 overflow-hidden">
                      <img alt="content" className="border object-cover object-center h-full w-full" src={award.image} />
                    </div>
                    <h2 className="tracking-wide text-xs title-font font-semibold text-indigo-500 mt-5 mb-1">{award.date}</h2>
                    <h2 className="text-xl font-medium title-font text-gray-900 ">{award.title}</h2>
                    <p className="text-indigo-800 inline-flex items-center mr-auto leading-none font-normal pr-3 py-2">
                    {award.people}
                    </p>
                    <p className="text-base leading-relaxed">{award.event_name}</p>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>
      </div>
    )
  }
  