import axios from 'axios';
import React, {useState, useEffect} from "react";
import Marquee from "react-fast-marquee";

import data from "../assets/json/news.json"

export const Latest = () => {

    const [news, setNews] = useState(data.news)

    const fetchNews = async () => {
        const result = await axios.get('https://admin.itranss.com/api/news/');
        if (result.data > 0) { setNews(result.data) }
    }

    useEffect(() => {
        fetchNews();
    }, [])


    return (
        <div className="text-gray-600 body-font">
            <div className="container px-5 py-24 mx-auto">
            <div className="flex flex-wrap w-full mb-20">
                <div className="lg:w-1/2 w-full mb-0">
                    <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">Latest News</h1>
                    <div className="h-1 w-20 bg-amber-700 rounded"></div>
                </div>
                {/* <p className="lg:w-1/2 w-full leading-relaxed text-gray-500">Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical gentrify, subway tile poke farm-to-table. Franzen you probably haven't heard of them man bun deep jianbing selfies heirloom.</p> */}
            </div>
                <Marquee gradientWidth={50} className="flex flex-nowrap overflow-x-scroll hide-scroll-bar -m-4">
                    { news.map((value, index) => (
                        <div key={index} className="p-4 w-80 md:w-96 flex-shrink-0">
                        <div className="h-full shadow-lg border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden hover:shadow-2xl transition-shadow duration-300 ease-in-out">
                            <img className="lg:h-48 md:h-36 h-48 w-full object-cover object-center" src={value.image}
                                alt={value.title} />
                            <div className="p-6">
                            <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">{value.date}</h2>
                            {value.title.length < 95
                                ?  <h1 className="title-font text-lg font-medium text-gray-900 mb-3">{value.title}</h1>
                                :  <h1 className="title-font text-lg font-medium text-gray-900 mb-3">{value.title.substring(0, 95)}...</h1>
                            }
                                {/* <p className="leading-relaxed mb-3">Photo booth fam kinfolk cold-pressed sriracha leggings jianbing
                                    microdosing tousled waistcoat.</p> */}
                                <div className="flex items-center flex-wrap ">
                                    <a target="_blank" rel="noreferrer" href={value.link} className="text-amber-700 inline-flex items-center md:mb-2 lg:mb-0">Learn More
                                        <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2"
                                            fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path d="M5 12h14"></path>
                                            <path d="M12 5l7 7-7 7"></path>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    ))}
                </Marquee>
            </div>
        </div>
    )
}