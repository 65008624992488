/* This example requires Tailwind CSS v2.0+ */
import { MailIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'
import { Popover, Transition } from '@headlessui/react'
import { Fragment } from 'react'

import data from "../assets/json/services.json"

export const ServicesComponent = () => {
  return (
    <div className="container mx-auto px-4 mb-10 sm:px-6 lg:px-8">
        <ul  className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
        {data.services.map((service) => (
            <li key={service.id} className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
            <img className="lg:h-56 md:h-36 w-full border-b rounded-t-lg object-cover object-center" src={service.image} alt={service.name} />
              <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={`
                      ${open ? '' : 'text-opacity-90'}
                        w-full flex items-center justify-between px-6 pb-6 pt-3 space-x-6
                        hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75
                    `}>
                      <div className="flex-1 py-4 truncate">
                        <div className="text-left">
                            <h3 className="text-gray-900 text-lg lg:text-xl font-medium truncate">{service.name}</h3>
                            <p className="text-sm text-gray-500">
                                  {service.desc}
                              </p>
                        </div>
                      </div>  
                      <div  className={`${open ? 'rotate-90 transform' : ''}
                      text-orange-800 inline-flex items-center transition duration-150 ease-in-out group-hover:text-opacity-80`}>
                        <svg className="w-8 h-8 ml-2" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M5 12h14"></path>
                        <path d="M12 5l7 7-7 7"></path>
                        </svg>
                      </div> 
                   </Popover.Button> 
                   <div>
                    <div className="-mt-3 flex border-t divide-gray-200">
                    <div className="w-0 flex-1 flex">
                        <Link
                        target="_blank" rel="noreferrer" to="/contact"
                        className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                        >
                        <MailIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
                        <span className="ml-3">Contact Us</span>
                        </Link>
                    </div>
                    </div>
                    </div>
                   <Transition
                   as={Fragment}
                   enter="transition ease-out duration-200"
                   enterFrom="opacity-0 translate-y-1"
                   enterTo="opacity-100 translate-y-0"
                   leave="transition ease-in duration-150"
                   leaveFrom="opacity-100 translate-y-0"
                   leaveTo="opacity-0 translate-y-1"
                   >
                    <Popover.Panel className="absolute left-1/2 z-10 mt-3 w-full -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
                      <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="relative grid gap-8 bg-white p-7 lg:grid-cols-1">
                          {service.features.map((service) => (
                            <div
                              key={service.id}
                              className="-m-3 flex items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                            >
                              <div className="ml-4">
                                <p className="text-sm font-medium text-gray-900">
                                  {service.name}
                                </p>
                                <p className="text-sm text-gray-500">
                                  {service.desc}
                                </p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                   </Transition>
                </>
              )}
              </Popover>
            {/* <div className="w-full flex items-center justify-between p-6 space-x-6">
                <div className="flex-1 py-4 truncate">
                <div className="text-left">
                  <h3 className="text-gray-900 text-xl font-medium truncate">{service.name}</h3>
                    <p className="text-sm text-gray-500">
                        {service.desc}
                    </p>
                </div>
                </div>
                <Link  className="text-orange-800 inline-flex items-center transition duration-150 ease-in-out group-hover:text-opacity-80">
                        <svg className="w-8 h-8 ml-2" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M5 12h14"></path>
                        <path d="M12 5l7 7-7 7"></path>
                        </svg>
                </Link>
            </div>
            <div>
                <div className="-mt-px flex divide-x divide-gray-200">
                <div className="w-0 flex-1 flex">
                    <Link
                    target="_blank" rel="noreferrer" to="/contact"
                    className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                    >
                    <MailIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
                    <span className="ml-3">Contact Us</span>
                    </Link>
                </div>
                </div>
            </div> */}

            </li>
        ))}
        </ul>
    </div>
  )
}
