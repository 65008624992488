// import axios from 'axios';
// import React, {useState, useEffect} from "react";

import { FullFooter } from "../components/fullFooter"
import { Navbar } from "../components/navbar"

import xyz from "../assets/json/people.json"
import { PeopleComponent } from '../components/poeple.component';


export const People = () => {

    // const [team, setTeam] = useState(xyz.team);
    // const [faculty, setFaculty] = useState(xyz.faculty);
    // const [student, setStudent] = useState(xyz.student);
    // const [alumni, setAlumni] = useState(xyz.alumni);

    // const fetchTeam = async () => {
    //   const result = await axios.get('https://admin.itranss.com/api/team/');
    //   if (result.data > 0) { setTeam(result.data) }
    // }

    // const fetchFaculty = async () => {
    //     const result = await axios.get('https://admin.itranss.com/api/faculty/');
    //     if (result.data > 0) { setFaculty(result.data) }
    // }

    // const fetchStudent = async () => {
    //     const result = await axios.get('https://admin.itranss.com/api/faculty/');
    //     if (result.data > 0) { setFaculty(result.data) }
    // }

    // const fetchAlumni = async () => {
    //     const result = await axios.get('https://admin.itranss.com/api/faculty/');
    //     if (result.data > 0) { setFaculty(result.data) }
    // }

       
    // useEffect(() => {
    //     fetchTeam();
    //     fetchFaculty();
    //     fetchStudent();
    //     fetchAlumni();
    // }, [])


    return (
        <div>
            <Navbar />
            <PeopleComponent team={xyz.team} faculty={xyz.faculty} student={xyz.student} alumni={xyz.alumni} />
            <FullFooter />
        </div>
    )
}