import axios from 'axios';
import React, {useState, useEffect} from "react";

import { Navbar } from "../components/navbar"
import { FullFooter } from "../components/fullFooter"
import { BookComponent } from "../components/book.component"
import { HeroComponent } from "../components/hero.component"

import data from "../assets/json/publications.json"


export const Book = () => {

    const [book, setBook] = useState(data.bookChapters)

    const fetchBook = async () => {
        const result = await axios.get('https://admin.itranss.com/api/book/');
        if (result.data > 0) { setBook(result.data) }
    }
       
    useEffect(() => {
        fetchBook();
    }, [])

    return (
        <div>
            <Navbar />
            <div className="bg-gray-100 pb-20"> 
            <HeroComponent
                title={"Book Chapters"} 
                desc={"We're advancing the state-of-the-art in artificial intelligence through fundamental and applied research in open collaboration with the community."} 
                img={"https://images.pexels.com/photos/4855436/pexels-photo-4855436.jpeg?auto=compress"} 
            />
            <BookComponent book={book}/>
            </div>
            <FullFooter />
        </div>
    )
}