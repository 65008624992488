import { HeroComponent } from "../components/hero.component"
import { Navbar } from "../components/navbar"
import { ServicesComponent } from "../components/services.component"
import { FullFooter } from "../components/fullFooter"

export const Services = () => {
    return (
        <div>
            <Navbar />
            <div className="bg-gray-100 pb-20"> 
            <HeroComponent 
                title={"Services"} 
                desc={"We're advancing the state-of-the-art in artificial intelligence through fundamental and applied research in open collaboration with the community."} 
                img={"https://images.pexels.com/photos/3183171/pexels-photo-3183171.jpeg?auto=compress"} 
            />
            <ServicesComponent />
            </div>
            <FullFooter/>
        </div>
    )
}