import { ContactComponent } from "../components/contact.component"
import { FullFooter } from "../components/fullFooter"
import { Navbar } from "../components/navbar"

export const Contact = () => {
    return (
        <div>
            <Navbar />
            <ContactComponent />
            <FullFooter />
        </div>
    )
}