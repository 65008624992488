import axios from 'axios';
import React, {useState, useEffect} from "react";

import { Navbar } from "../components/navbar"
import { FullFooter } from "../components/fullFooter"
import { NewsComponent } from "../components/news.component"

import data from "../assets/json/news.json"

export const News = () => {

    const [news, setNews] = useState(data.news)

    const fetchNews = async () => {
        const result = await axios.get('https://admin.itranss.com/api/news/');
        if (result.data > 0) { setNews(result.data) }
    }

    useEffect(() => {
        fetchNews();
    }, [])

    return (
        <div>
            <Navbar />
            <NewsComponent news={news} />
            <FullFooter />
        </div>
    )
}