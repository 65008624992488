import { NavLink } from 'react-router-dom'
import { Fragment } from 'react'

// import Logo from '../assets/media/logo.png'
import fullLogo from '../assets/media/fullLogo.png'

import { Disclosure} from '@headlessui/react'
import { 
  MenuIcon, 
  XIcon,
} from '@heroicons/react/outline'

import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const callsToAction = [
  { name: 'Projects', href:  '/projects' }, 
  { name: 'Publications', href:  '/publications' }, 
  { name: 'Journal Papers', href:  '/journals' }, 
  { name: 'Presentations', href:  '/presentations' }, 
  { name: 'Book Chapters', href:  '/books' },
  // { name: 'Conference Proceedings', href:  '/conference' },
]


const navigation = [
    { name: 'Home', href:  '/' },
    { name: 'About', href:  '/about' },
    { name: 'Research', href:  '/research' },
    { name: 'Services', href:  '/services' },
    { name: 'News', href:  '/news' },
    { name: 'Awards', href:  '/awards' },
    { name: 'People', href:  '/people' },
    // { name: 'Gallery', href:  '/gallery' },
    { name: 'Contact', href:  '/contact' },
]

export const Navbar = () => {
  return (
    <Disclosure as="nav" className="bg-white shadow-xl w-full fixed z-20">
      {({ open }) => (
        <>
          <div className="container mx-auto">
            <div className="flex justify-between h-16 mx-4 md:mx-0">
              <div className="flex">
                <div className="flex-shrink-0 flex items-center">
                <a href="/">
                  <img
                    className="block h-14 md:h-16 w-auto"
                    src={fullLogo}
                    alt="Intelligent Transportation Systems & Services (ITranSS)"
                  />
                </a>
                </div>
                
              </div>
              <div className="hidden lg:ml-6 lg:flex lg:items-center">
              <div className="hidden sm:ml-6 sm:flex sm:space-x-2 lg:space-x-3 xl:space-x-6 2xl:space-x-8">
                {navigation.map((item, index) => {
                  return item.name === 'Research' 
                  ? 
                  <Popover key={index}>
                    {({ open }) => (
                      <>
                        <Popover.Button
                          className={classNames(
                            open ? ' text-gray-900' : 'text-gray-500 hover:text-gray-700',
                            'group rounded-md inline-flex px-1 pt-1 items-center text-sm font-medium'
                          )}
                        >
                          <span>Research</span>
                          <ChevronDownIcon
                            className={classNames(
                              open ? 'text-gray-600' : 'text-gray-400',
                              'h-5 w-5 group-hover:text-gray-500'
                            )}
                            aria-hidden="true"
                          />
                        </Popover.Button>
    
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 -translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 -translate-y-1"
                        >
                          <Popover.Panel className="hidden md:block absolute z-10 top-full inset-x-0 transform shadow-lg bg-white">
                            <div className="bg-gray-100 border-t">
                              <div className="container mx-auto space-y-6 px-4 py-4 sm:flex sm:justify-end sm:space-y-0 sm:space-x-10 sm:px-6 lg:px-8">
                                {callsToAction.map((item, index) => (
                                  <div key={index} className="flow-root text-center">
                                    <NavLink
                                     key={index}
                                      to={item.href}
                                      className = {({isActive}) => {
                                        return isActive ? "border-amber-700 text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium" : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                                      }}
                                    >
                                      {item.name}
                                    </NavLink>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover> 
                  : <NavLink
                    key={index}
                    to={item.href}
                    end
                    className = {({isActive}) => {
                      return isActive ? "border-amber-700 text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium" : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                    }}
                  >
                    {item.name}
                  </NavLink>
                })}
                </div>
              </div>
              <div className="-mr-2 flex items-center lg:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-amber-700">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="lg:hidden">
            <div className="pt-2 pb-3 space-y-1">
              {/* Current: "bg-amber-50 border-amber-700 text-amber-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}

              {navigation.map((item, index) => (
                item.name === 'Research' 
                ?
                <div key={index} className="space-y-1">
                    {callsToAction.map((value, index) => (
                      <Disclosure.Button
                      key={index}
                      as="a"
                      href={value.href}
                      className="bg-amber-50 border-amber-700 text-amber-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium hover:bg-amber-700 hover:text-white"       
                      >
                        {value.name}
                        </Disclosure.Button>
                    ))}
                </div>
                :
                  <Disclosure.Button
                    as="a"
                    href={item.href}
                    className="bg-amber-50 border-amber-700 text-amber-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium hover:bg-amber-700 hover:text-white"    
                    >
                      {item.name}
                  </Disclosure.Button>
              ))}
              {/* <Disclosure.Button
                as="a"
                href="/"
                activeClassName ="border-amber-700 text-gray-900"
                className="bg-amber-50 border-amber-700 text-amber-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                
              >
                Home
              </Disclosure.Button>
              <Disclosure.Button
                as="a"
                href="/"
                activeClassName ="border-amber-700 text-gray-900"
                className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
              >
                Publication
              </Disclosure.Button>
              <Disclosure.Button
                as="a"
                href="/"
                activeClassName ="border-amber-700 text-gray-900"
                className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
              >
                Projects
              </Disclosure.Button>
              <Disclosure.Button
                as="a"
                href="/project"
                activeClassName ="border-amber-700 text-gray-900"
                className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
              >
                Services
              </Disclosure.Button> */}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
