import axios from 'axios';
import React, {useState, useEffect} from "react";

import { Navbar } from "../components/navbar"
import { FullFooter } from "../components/fullFooter"
import { JournalComponent } from "../components/journal.component"
import { HeroComponent } from "../components/hero.component"

import data from "../assets/json/papers.json"

export const Journal = () => {

    const [journal, setJournal] = useState(data.journal)

    const fetchJournal = async () => {
        const result = await axios.get('https://admin.itranss.com/api/journal/');
        if (result.data > 0) { setJournal(result.data) }
    }

    useEffect(() => {
        fetchJournal();
    }, [])

    return (
        <div>
            <Navbar />
            <div className="bg-gray-100 pb-20"> 
            <HeroComponent
                title={"Journal Papers"} 
                desc={"We're advancing the state-of-the-art in artificial intelligence through fundamental and applied research in open collaboration with the community."} 
                img={"https://images.pexels.com/photos/1018133/pexels-photo-1018133.jpeg?auto=compress"} 
            />
            <JournalComponent journal={journal}/>
            </div>
            <FullFooter />
        </div>
    )
}