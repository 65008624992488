
export const HeroComponent = ({title, desc, img}) => {
  return (
    <div className="bg-gray-100 pb-20">
      {/* Header */}
      <div className="relative pt-20 pb-56 2xl:pb-[30rem] bg-gray-800">
        <div className="absolute inset-0">
          <img
            className="w-full h-full object-cover"
            src={img}
            alt=""
          />
          <div className="absolute inset-0 bg-stone-400 mix-blend-multiply" aria-hidden="true" />
        </div>
        <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
          {/* Background Image heigth */}
        </div>
      </div>

      {/* Overlapping cards */}
      <section
        className="-mt-48 container mx-auto relative z-10 px-4 sm:px-6 lg:px-8"
        aria-labelledby="project-heading">
        <div>
            <div className="bg-white rounded-lg shadow-xl mt-20">
            <div className="relative mx-16 py-16 pb-14 mb-14">
              <h1 className="text-4xl font-semibold tracking-tight md:text-5xl">
                {title}
                </h1>
              <div className="h-1 mt-4 w-full bg-orange-800 rounded"></div>
              {/* <p className="mt-6 text-xl text-gray-900">
                {desc}
              </p> */}
              </div>
            </div>
        </div>
      </section>

        

    </div>
  )
}
