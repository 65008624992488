import { CTA } from "../components/cta"
import { FullFooter } from "../components/fullFooter"
import { Hero } from "../components/hero"
import { Latest } from "../components/latest"
import { Navbar } from "../components/navbar"

export const Home = () => {
    return (
        <div>
            <Navbar />
            <Hero />
            <Latest />
            <CTA />
            <FullFooter />
        </div>
    )
}