import axios from 'axios';
import React, {useState, useEffect} from "react";

import { ProjectCard } from "../components/project.card.component"
import { Navbar } from "../components/navbar"
import { ProjectComponent } from "../components/project.component"
import { FullFooter } from "../components/fullFooter"

import data from "../assets/json/projects.json"

export const Projects = () => {

    const [project, setProject] = useState(data.projects)

    const fetchProject = async () => {
        const result = await axios.get('https://admin.itranss.com/api/project/');
        if (result.data > 0) { setProject(result.data) }
    }

    useEffect(() => {
        fetchProject();
    }, [])

    return (
        <div>
            <Navbar />
            <div className="bg-gray-100 pb-20"> 
                <ProjectComponent />
                <ProjectCard project={project}/>
            </div>
            <FullFooter />
        </div>
    )
}