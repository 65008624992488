
export const Hero = () => {
    return (
      <div className="relative bg-gray-800 py-32 px-6 sm:py-40 sm:px-12 lg:px-16 h-screen content-end">
        <div className="absolute inset-0 overflow-hidden">
          <video loop muted autoPlay 
            src="https://rowan-intelligence-transportation-system.s3.amazonaws.com/media/video/alphaVideo.mov"
            alt="Rowan Intelligence Transportation Systems"
            className="w-full h-full object-center object-cover"
          />
        </div>
        <div aria-hidden="true" className="absolute inset-0 bg-gray-900 bg-opacity-75" />
        <div className="relative max-w-3xl flex flex-col items-start pt-[30vh]">
          <h2 className="text-3xl font-extrabold tracking-tight text-white sm:text-4xl">
            Intelligent <br />
            Transportation <br />
            Systems & Services
            </h2>
          <p className="mt-3 text-xl text-white">
          We are enhancing the state-of-the-art in transportation systems through fundamental and applied research in collaboration with industry professionals.
          </p>
          <a
            href="/about"
            className="mt-8 w-full block bg-white border border-transparent rounded-md py-3 px-8 text-base font-medium text-gray-900 hover:bg-gray-100 sm:w-auto"
          >
            Learn more
          </a>
        </div>
      </div>
    )
  }