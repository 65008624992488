
export const PresentationComponent = ({presentation}) => {
    return (
        <div> 
            { presentation.map( value => (
                <div key = {value.id} className="container px-6 lg:px-8 py-10 mx-auto">
                <div className="-my-8 px-10 bg-white rounded-lg shadow-xl">
                <div className="pt-12 flex flex-wrap md:flex-nowrap"> 
                    <div className="md:flex-grow">
                    <h2 className="text-2xl font-medium text-gray-900 title-font mb-1.5">{value.title}</h2>
                    <h3 className="text-md font-bold text-orange-900 mb-4">{value.event_name}</h3>
                    </div>

                    <div className="md:w-64 pt-1.5 flex-shrink-0 flex flex-col text-right">
                        <span className="font-semibold title-font text-gray-700">{value.date}</span>
                    </div>
                </div>
                <div className="flex items-center flex-wrap pb-8 mb-3 mt-auto w-full">
                    <span className="text-gray-800 inline-flex items-center mr-auto leading-none font-medium pr-3 py-1">
                    {value.people}
                    </span>
                    { value.link 
                       ? <a target="_blank" rel="noreferrer" href={value.link} className="text-orange-800 inline-flex items-center">Read more
                        <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M5 12h14"></path>
                        <path d="M12 5l7 7-7 7"></path>
                        </svg>
                        </a> 
                       : null
                    }
                    
                </div>
                </div>
            </div>
            )) }

        </div>
    )
}
