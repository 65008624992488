import axios from 'axios';
import React, {useState, useEffect} from "react";

import { Navbar } from "../components/navbar"
import { FullFooter } from "../components/fullFooter"
import { PresentationComponent } from "../components/presentation.component"
import { HeroComponent } from "../components/hero.component"

import data from "../assets/json/presentations.json"

export const Presentation = () => {

    const [presentation, setPresentation] = useState(data.presentations)

    const fetchPresentation = async () => {
        const result = await axios.get('https://admin.itranss.com/api/presentation/');
        if (result.data > 0) { setPresentation(result.data) }
    }

    useEffect(() => {
        fetchPresentation();
    }, [])



    return (
        <div>
            <Navbar />
            <div className="bg-gray-100 pb-20"> 
            <HeroComponent
                title={"Presentations"} 
                desc={"We're advancing the state-of-the-art in artificial intelligence through fundamental and applied research in open collaboration with the community."} 
                img={"https://images.pexels.com/photos/3183150/pexels-photo-3183150.jpeg?auto=compress"} 
            />
            <PresentationComponent presentation={presentation} />
            </div>
            <FullFooter />
        </div>
    )
}